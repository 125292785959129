import styled from 'styled-components';
import {SectionHeading} from '../../../components/Common/common.style';
import {device} from '../../../components/Common/device'
import {SocialFacebook} from '@styled-icons/typicons/SocialFacebook'
import {SocialTwitter} from '@styled-icons/typicons/SocialTwitter'
import {SocialLinkedin} from '@styled-icons/typicons/SocialLinkedin'
import {SocialInstagram} from '@styled-icons/typicons/SocialInstagram'

export const TeamSection = styled.section`
    padding:100px 0px 80px;

    @media ${device.tablet} {
        padding:80px 5px 60px;
    }
`;

export const Heading = styled(SectionHeading)`
    margin-bottom:55px;
    text-align: center;

    @media ${device.tablet} {
        margin-bottom:40px;
    }
`;

export const SliderOuterWrapper = styled.div`
    margin-left:-15px;
    margin-right:-15px;
`;

export const TeamSliderWrapper = styled.div`
    outline:0;
`;

export const TeamSliderLayout = styled.div`
    padding: 20px 15px;
    position:relative;
`;

export const ImageLayout = styled.div`
    text-align: center;
    justify-content: center;
    display: flex;
    
    .teamImg{
        height:250px;
        backface-visibility: hidden;
        -webkit-transform: scale(1);
        transform: scale(1);
        transition: transform .3s ease-in-out !important;
        -webkit-transition: -webkit-transform .3s ease-in-out !important;
    }
`;
export const TeamImgFigure = styled.figure`
    overflow: hidden;
    margin-bottom:0px;
    position:relative;
    width:100%;

    :hover .teamImg{
        -webkit-transform: scale(1.05);
        transform: scale(1.05);
    }

    .teamImg{
        height:250px;
        backface-visibility: hidden;
        -webkit-transform: scale(1);
        transform: scale(1);
        transition: transform .3s ease-in-out !important;
        -webkit-transition: -webkit-transform .3s ease-in-out !important;
    }
`;

export const TeamDescWrapper = styled.div`
    box-shadow: 0px 0px 15px 0px #ddd;
    margin-top: -30px;
    margin-right: 10px;
    margin-left: 10px;
    h5{
        margin-bottom:10px;
        line-height:1;
    }
    p{
        text-align: center;
        margin-bottom:0px;
        line-height:1;
    }
    a:hover{
        color:#ef5350;
    }
`;

export const TeamDesc = styled.div`
    text-align:center;
    background:#fff;
    position:relative;
    padding:15px 10px;
`;


export const SocialLayout = styled.div`
    display:flex;
    align-items:center;
    justify-content:center;
    transition:0.3s all;
    margin-top:5px;
`;

export const FbIcon = styled(SocialFacebook)`
    width: 32px;
    padding: 6px;
    border-radius: 100%;
    transition:all .5s;
    color: #006fb7;
    cursor:pointer;

    :hover{
        transform:rotate(30deg);
        transition:all .5s;
    }
`;

export const TwitterIcon = styled(SocialTwitter)`
    width: 32px;
    padding: 6px;
    border-radius: 100%;
    transition:all .5s;
    color: #02bdf0;
    cursor:pointer;

    :hover{
        transform:rotate(30deg);
        transition:all .5s;
    }
`;

export const LinkedinIcon = styled(SocialLinkedin)`
    width: 32px;
    padding: 6px;
    border-radius: 100%;
    transition:all .5s;
    color: #1d1666;
    cursor:pointer;

    :hover{
        transform:rotate(30deg);
        transition:all .5s;
    }
`;

export const InstaIcon = styled(SocialInstagram)`
    width: 32px;
    padding: 6px;
    border-radius: 100%;
    transition:all .5s;
    color: #ff24b4;
    cursor:pointer;

    :hover{
        transform:rotate(30deg);
        transition:all .5s;
    }
`;

export const NextPrev = styled.div`
    text-align:center;
`;

export const ImgButton = styled.button`
    border:none;
    background:none;
    outline:0;

    :focus{
        outline:0;
    }
`;

export const PrevImg = styled.img`
    margin:20px 0px 0px;
    width: 40px;
    cursor:pointer;
    transition:all .4s;
    :hover
    {
        transform:scale(1.1);
        transition:all .4s;
    }
`;

export const NextImg = styled.img`
    margin:20px 0px 0px;
    width: 40px;
    cursor:pointer;
    transition:all .4s;
    :hover
    {
        transform:scale(1.1);
        transition:all .4s;
    }
`;
