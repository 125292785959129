import React from 'react';
import {WhyChooseUsSection,WhyChooseUsLeftWrapper,WhyChooseUsLeftLayout,WhyChooseUsHeading,WhyChooseUsSingle,
    CheckIcon,WhyChooseUsRightLayout
} from './whychooseus.style';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useStaticQuery, graphql } from 'gatsby';
import GatsImg from "gatsby-image";

const WhyChooseUs  = () => {
    const JSONData  = useStaticQuery(graphql`
    query {
        aboutPage1Json{
            WhyChooseUs{
                WhyChooseUsHeading
                Content1
                Content2
                Content3
                LeftImage{
                    childImageSharp{
                        fluid(quality: 100){
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
  `);
  const WhyChooseUsData = JSONData.aboutPage1Json.WhyChooseUs;

    return (
            <WhyChooseUsSection>
                <WhyChooseUsRightLayout>
                    <GatsImg
                        fluid={WhyChooseUsData.LeftImage.childImageSharp.fluid}
                        alt=""
                        className="RightImage"
                    />
                </WhyChooseUsRightLayout>
                <WhyChooseUsLeftWrapper>
                <WhyChooseUsLeftLayout>

                    <WhyChooseUsHeading>
                    {WhyChooseUsData.WhyChooseUsHeading}
                    </WhyChooseUsHeading>
                    <h5>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h5>
                    <WhyChooseUsSingle>

                        <ul>
                            <li>
                                <CheckIcon />
                                <p>Easily generate Lorem Ipsum placeholder text in any number of characters, words sentences or paragraphs. Learn about the origins of the passage and its.</p>
                            </li>
                            <li>
                                <CheckIcon />
                                <p>In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate the visual form of a document or a typeface without relying.</p>
                            </li>
                            <li>
                                <CheckIcon />
                                <p>Easily generate Lorem Ipsum placeholder text in any number of characters, words sentences or paragraphs. Learn about the origins of the passage and its.</p>
                            </li>
                        </ul>
                    </WhyChooseUsSingle>
                </WhyChooseUsLeftLayout>
                </WhyChooseUsLeftWrapper>
            </WhyChooseUsSection>
    );
}
export default WhyChooseUs;
