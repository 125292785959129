import styled from 'styled-components';
import {device} from '../../../components/Common/device';
import {SectionHeading} from '../../../components/Common/common.style';
import {Check} from'@styled-icons/fa-solid/Check';

export const WhyChooseUsSection = styled.section`
    display: flex;
    align-items:flex-start;

    @media ${device.tablet} {
        flex-direction:column;
        margin-top:0px;
    }
`;

export const WhyChooseUsLeftLayout = styled.div`
    padding: 50px;

    @media ${device.tablet} {
        width:100%;
        padding: 20px;
        top:0px;
        max-width:540px;
        margin:0px auto;
        margin-top:20px;
    }

    h5{
        color: #fff;
        margin-bottom: 20px;
    }
`;

export const WhyChooseUsLeftWrapper = styled.div`
    width: 50%;
    background: #252525;
    position: relative;

    @media ${device.tablet} {
        width:100%;
        margin:0px auto;
    }
`;

export const WhyChooseUsHeading = styled(SectionHeading)`
    text-align:left;
    margin-bottom:20px;
    color:#fff;
`;

export const WhyChooseUsSingle = styled.div`
    ul{
        padding:0;
        margin:0;
    }
    ul li{
        list-style:none;
        display:flex;
        align-items:flex-start;
        margin-bottom: 15px;
    }
    p{
        margin-left:10px;
        color:#fff;
    }
`;

export const CheckIcon = styled(Check)`
    width: 25px;
    height: 25px;
    padding: 6px;
    color: #ef4028;
    background: #fff;
    border-radius: 100%;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    margin-top:5px;
`;


export const WhyChooseUsRightLayout = styled.div`
    width:50%;
    margin-top:80px;

    .RightImage{
        width: 100%;
        height: 500px;
        object-fit: cover;
    }

    @media ${device.tablet} {
        width:100%;
        margin-top:0px;
    }
`;
