import React from "react";
import HeaderMenuBS from "../components/HeaderMenuBS"
import Banner from "../components/CommonBanner"
import AboutUs from "../containers/AboutPage1/AboutUs"
import BackgroundImg from '../assets/about-1-images/banner.jpg';
import Stats from "../containers/AboutPage1/Stats"
import WhyChooseUs from "../containers/AboutPage1/WhyChooseUs"
import Team from "../containers/AboutPage1/Team"
import Footer from "../components/Footer"
import SEO from "../components/seo";
import Layout from "../components/layout";

const AboutPage1 = () => (
    <Layout>
        <SEO 
            title="Beryl - Business, Corporate Gatsbyjs Website | Gatsbyjs Website Themes"
            description="Buy Responsive Business and Corporate Themes built with GatsbyJS, React Bootstrap and Styled-Components"
        />
        <HeaderMenuBS/>
        <Banner 
            BackgroundImg={BackgroundImg}
            pageTitle="About Our Company"
            breadCrumbsFirst="Home"
            breadCrumbsSecond="About"
            breadCrumbsFirstLink="/"
            breadCrumbsSecondLink="/aboutus-1"
        />
        <AboutUs />
        <Stats />
        <WhyChooseUs />
        <Team />
        <Footer />
    </Layout>
)

export default AboutPage1;