import styled from 'styled-components';
import {device} from '../../../components/Common/device';
import {Commonbtn} from '../../../components/Common/common.style';
import {Row} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

export const AboutUsSection = styled.section`
    padding:100px 0px 0px;

    @media ${device.tablet} {
        padding:80px 5px 0px;
    }
`;

export const CustomRow = styled(Row)`
    align-items:center;
    @media ${device.laptop} {
        flex-direction:column;
    }
`;

export const TextLayout = styled.div`

    @media ${device.laptop} {
        margin-bottom:40px;
    }

    h2{
        text-align:left;
    }

    h5{

    }

    ul{
        list-style:none;
        margin:0;
    }
    ul li{
        display:flex;
        align-items:flex-start;
        margin-bottom: 10px;
    }

    ul li p{
        line-height:1;
        margin-bottom:0;
        margin-left:20px;
        line-height:25px;
    }

    ul li img{
        margin-top:7px;
    }
`;



export const ContactUsBtn = styled(Commonbtn)`
    margin-top:15px;
`;

export const ImageHolder = styled.div`
    padding-right:50px;

    @media ${device.laptop} {
        padding-right:0px;
    }
`;
