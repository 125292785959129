import styled from 'styled-components';
import {device} from '../../../components/Common/device';
import {SectionHeading} from '../../../components/Common/common.style';

export const StatsSection = styled.section`
    background-repeat:no-repeat;
    background-size:cover;
    padding:100px 0px 100px;

    @media ${device.tablet} {
        padding:80px 5px 80px;
    }
`;

export const HeadingLayout = styled.div`
    margin-bottom:30px;
`;

export const MainHeading = styled(SectionHeading)`
    text-align:left;
`;

export const Border = styled.img`
    height:20px;
    width:100px;
`;

export const StatsSingle = styled.div`
    text-align:center;
    margin-bottom:30px;

    img{
        width: auto;
        height: 50px;
        margin-bottom:20px;
    }
    h1{
        margin-bottom:5px;
        line-height:1;

        @media ${device.tablet} {
            line-height:1;
        }
    }

    p{
        text-align:center;
        margin-bottom:0px;
    }
`;


export const AboutStatsWrapper = styled.div`
    box-shadow: 0px 0px 8px 1px #ddd;
    background: #fff;
    padding: 30px 20px 0px;
    border-radius: 6px;
`;
