import React from 'react';
import {AboutUsSection,CustomRow,TextLayout,ImageHolder
} from './aboutus.style';
import {Container,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import GatsImg from "gatsby-image";
import { useStaticQuery, graphql } from 'gatsby';

const AboutUs = () => {
    const JSONData  = useStaticQuery(graphql`
        query {
                aboutPage1Json{
                    AboutUs{
                       Heading
                       Description1
                       Description2
                       ArrowImage
                       ListText1
                       ListText2
                       ListText3
                       Img{
                        childImageSharp{
                            fluid(quality: 100){
                            ...GatsbyImageSharpFluid
                            }
                        }
                       }
                    }
                }
            }
    `);
    const AboutUsData = JSONData.aboutPage1Json.AboutUs;
        return (
        <AboutUsSection id="AboutUsContainer">
            <Container>
                <CustomRow>
                    <Col md={12} lg={6}>
                        <TextLayout>
                            <h2>
                            {AboutUsData.Heading}
                            </h2>
                            <h5>{AboutUsData.Description1}</h5>
                            <p>
                                {AboutUsData.Description2}
                            </p>
                            <ul>
                                <li>
                                    <img alt="" src={AboutUsData.ArrowImage} />
                                    <p>{AboutUsData.ListText1}</p>
                                </li>
                                <li>
                                    <img alt="" src={AboutUsData.ArrowImage} />
                                    <p>{AboutUsData.ListText2}</p>
                                </li>
                                <li>
                                    <img alt="" src={AboutUsData.ArrowImage} />
                                    <p>{AboutUsData.ListText3}</p>
                                </li>
                            </ul>
                        </TextLayout>
                    </Col>
                    <Col md={12} lg={6}>
                        <ImageHolder>
                            <GatsImg
                                fluid={AboutUsData.Img.childImageSharp.fluid}
                                alt=""
                            />
                        </ImageHolder>
                    </Col>
                </CustomRow>
            </Container>
        </AboutUsSection>
    );
}

export default AboutUs;
